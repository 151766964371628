
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      ctx: null,
      tl: null,
      isOpen: false,
      containsActiveLink: false,
      blueprintLoaderEL: null,
    }
  },

  computed: {
    navItemLevel1() {
      return this.data
    },
  },

  watch: {
    $route() {
      this.isOpen = false

      this.checkIfContainsActiveLink()
    },
  },

  mounted() {
    this.checkIfContainsActiveLink()
  },

  methods: {
    open() {
      this.isOpen = true

      this.$nuxt.$emit('nav-drodown-opened', this.isOpen)
    },

    close() {
      this.isOpen = false

      this.$nuxt.$emit('nav-drodown-closed', this.isOpen)
    },

    navItemText(page) {
      if (page.text) return page.text

      if (page.heros) {
        return page.heros[0].text
      }

      return null
    },

    shouldRenderLink(navItemLevel2) {
      return (
        navItemLevel2.children.length === 0 &&
        this.navItemText(navItemLevel2.page)
      )
    },

    checkIfContainsActiveLink() {
      this.$nextTick(() => {
        this.containsActiveLink = !!this.$el.querySelector('.nuxt-link-active')
      })
    },

    // Transition Hooks:

    beforeEnter() {
      this.blueprintLoaderEL = document.querySelector('.blueprint-loader')
    },

    enter() {
      this.ctx = this.$gsap.context(() => {
        // do nothing when an existing timeline is still animating
        if (this.tl && this.tl.isActive()) {
          return
        }

        // otherwise animate that shit
        this.tl = this.$gsap
          .timeline({
            defaults: {
              duration: 0.2,
            },
          })
          .to('.dropdown-menu', { opacity: 1, y: 0 })
      }, this.$el)
    },

    beforeLeave() {
      this.$el.classList.add('pointer-events-none')
    },

    leave() {
      if (this.tl.isActive()) {
        return
      }

      this.tl.reverse()
    },

    afterLeave() {
      this.$el.classList.remove('pointer-events-none')

      this.ctx.revert()
    },
  },
}
